import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class alert {
  public constructor(public router: Router, private toastr: ToastrService) {}

  responseAlert(text, icon) {
    Swal.fire({
      html: text,
      icon: icon,
      customClass: {
        container: "ingreditent_title",
      },
    });
  }

  opensweetalert() {
    Swal.fire({
      text: "Hello!",
      icon: "success",
    });
  }

  opensweetalertdng() {
    Swal.fire("Oops...", "Something went wrong!", "error");
  }

  FunctionByConfirm(val) {
    let value = val;
    return value;
  }

  opensweetalertcst() {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  subscribe() {
    Swal.fire({
      title: "Welcome to the Pack!",
      text: "We'll keep you updated with our latest news, learnings and promotional campaigns!",
      icon: "success",
      showCancelButton: false,
      cancelButtonText: "Okay",
    }).then((result) => {
      if (result.value) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  actionResponse(message: string, messageType: string) {
    const options = {
      closeButton: true,
      positionClass: "toast-top-right",  // Ensures the toaster appears on the top-right
      timeOut: 3000,
      progressBar: true,  // Optional: Adds a progress bar to the toast
    };

    switch (messageType) {
      case "success":
        this.toastr.success(message, "Success", options);
        break;
      case "error":
        this.toastr.error(message, "Error", options);
        break;
      case "warning":
        this.toastr.warning(message, "Warning", options);  // Using 'warning' for correct toastr type
        break;
      default:
        this.toastr.info(message, "Info", options);
        break;
    }
  }

  confirmAlert(message: string, confirmCallback: Function) {
    Swal.fire({
      title: message,
      icon: "warning",
      position: "top",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmCallback();
      }
    });
  }

  // New method to show loading indicator
  showLoadingIndicator(message: string = 'Loading...') {
    Swal.fire({
      title: message,
      allowOutsideClick: false,
      text: 'Please wait...',
      didOpen: () => {
        Swal.showLoading();  // Shows the loading icon when the alert is opened
      }
    });
  }

  // Method to close the loading indicator
  closeLoadingIndicator() {
    Swal.close();  // Closes the Swal modal, including the loading indicator
  }
}
