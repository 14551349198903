<div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="" style="background-color: #38414a;box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15)">
        <app-sidebar></app-sidebar>
        <div class="" style="background-color: #38414a;box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <!-- <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div> -->
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
</div>