import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";

import { RouterModule } from "@angular/router";



const sharedComponents = [
  SidebarComponent,
  NavbarComponent,
  FooterComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    sharedComponents,
  ],
  exports: [
    sharedComponents
  ],
  providers: [
  ]
})
export class ComponentsModule {}
